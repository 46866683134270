import React, { useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Col } from '@thd-olt-component-react/core-ui';
import { CheckAvailabilityContext } from '../../../CheckAvailabilityContext';
import { determineZipCode } from '../../../helpers/utils';
import './input-form.scss';
import { DELIVERY_ZIP_INPUT } from '../../../helpers/constants';

export const InputForm = ({ onSubmit, errorMessage, placeholderText }) => {
  const {
    inputHeaderTitle,
    zip,
    setZip
  } = useContext(CheckAvailabilityContext);

  const inputElement = useRef(null);

  const [_zip, _setZip] = useState(determineZipCode(zip));

  const shouldDisableInput = !_zip;
  const [inputButtonDisabled, setInputButtonDisabled] = useState(shouldDisableInput);

  const onInputChange = (event) => {
    const numericalZip = event?.target?.value?.replace(/\D/, '');
    const isDisabled = numericalZip.length !== 5;
    if (!isDisabled) {
      event?.target?.setCustomValidity(''); // eslint-disable-line no-unused-expressions
    }
    _setZip(numericalZip);
    setInputButtonDisabled(isDisabled);
  };

  return (
    <div className={'input-form ' + (errorMessage ? 'check-availability__error' : '')}>
      {inputHeaderTitle
        && (
          <Col nopadding className="input-form__header-title">
            <b>{inputHeaderTitle}</b>
          </Col>
        )}
      <Col nopadding>
        <form
          id="zip-code-availability-form"
          className="check-availability__zip-code-wrapper"
          onSubmit={(event) => {
            event.preventDefault();
            setZip(_zip);
            if (onSubmit) onSubmit(event, _zip);
          }}
        >
          <Col fallback="8" nopadding>
            <span className={`form-input${placeholderText === DELIVERY_ZIP_INPUT ? ' drawer-modal' : ''}`}>
              <input
                ref={inputElement}
                type="tel"
                pattern="[0-9]*"
                placeholder={placeholderText}
                maxLength="5"
                className="form-input__field"
                id="check-availability__zip-code"
                name="check-availability__zip-code"
                autoComplete="off"
                onChange={onInputChange}
                value={_zip}
              />
            </span>
          </Col>
          <Col fallback="4" nopadding>
            <button
              type="submit"
              disabled={inputButtonDisabled}
              data-testid="check-avail-bttn"
              className={placeholderText !== DELIVERY_ZIP_INPUT ? 'bttn-outline bttn-outline--primary'
                : 'bttn bttn--primary'}
            >
              {/* TODO: Why does a span have a role button inside of a button??? */}
              <span role="button" tabIndex={0} className="bttn__content">Update</span>
            </button>
          </Col>
          <Col fallback="12" nopadding className="check-availability__error-text">
            {errorMessage}
          </Col>
        </form>
      </Col>
    </div>
  );
};

InputForm.propTypes = {
  onSubmit: PropTypes.func,
  errorMessage: PropTypes.string,
  placeholderText: PropTypes.string
};

InputForm.defaultProps = {
  onSubmit: null,
  errorMessage: null,
  placeholderText: 'Tell us your ZIP Code'
};
