import React from 'react';
import PropTypes from 'prop-types';
import { RectShape, TextRow } from '@thd-olt-component-react/loading-placeholder';
import { Col, Row } from '@thd-olt-component-react/grid';
import '../../styles/dimension.scss';

const DimensionsPlaceholder = ({ isGetItFastOnPlp }) => {
  return (
    <div className="results-dimensions" data-component="DimensionsPlaceholder">
      {isGetItFastOnPlp
          && (
            <div className="results-dimensions__placeholder--desktop-wrapper">
              {[...Array(3)].map((n, i) => {
                return <RectShape style={{ width: '220px', height: '40px', marginRight: '10px' }} color="#E0E0E0" />;
              })}
            </div>
          )}
    </div>
  );
};

const DimensionsPlaceholderMobile = () => {
  return (
    <Col className="results-dimensions__placeholder--wrapper">
      <Row>
        <div className="results-dimensions__placeholder--mini" data-component="DimensionsPlaceholderMobile">
          {[...Array(3)].map((n, i) => {
            return <RectShape style={{ width: '115px', height: '40px', margin: '5px' }} color="#E0E0E0" />;
          })}
        </div>
      </Row>
    </Col>
  );
};

DimensionsPlaceholder.propTypes = {
  isGetItFastOnPlp: PropTypes.bool,
};

DimensionsPlaceholder.defaultProps = {
  isGetItFastOnPlp: false,
};

DimensionsPlaceholder.displayName = 'DimensionsPlaceholder';
DimensionsPlaceholderMobile.displayName = 'DimensionsPlaceholderMobile';

export { DimensionsPlaceholder, DimensionsPlaceholderMobile };
