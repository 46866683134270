import React, { useState, useEffect, useContext, useCallback } from 'react';
import { RevJetTag, TagProperties } from '@revjet/react-revjet-tag';
import { string, bool, shape, object } from 'prop-types';
import { ExperienceContext, useConfigService } from '@thd-nucleus/experience-context';
import { Carousel } from '@thd-olt-component-react/carousel';
import { useHelmet, LinkTag } from '@thd-nucleus/thd-helmet';
import { useThdCustomer } from '@thd-olt-functional/customer-information';
import {
  params,
  customType,
  fragment,
  shape as shapeType,
  string as stringType,
  bool as boolType,
  number as numberType,
  useDataModel
} from '@thd-nucleus/data-sources';
import classNames from 'classnames/bind';
import { makeApiRequest, shouldMakeApiRequest } from '../../utils/requestHelpers';
import {
  initDefaultBannerImpressionWatchers,
  initImpressionWatchers
} from '../../utils/initImpressionWatchers';
import {
  getSchema,
  getSkus,
  getDefaultBanner,
  shouldSkipDataModel,
  triggerNewRelic,
  parseIfJson,
  isBrandFpn, checkIfPageIsInAdServerTest, persistClickConversionBeacon, triggerNewRelicTimeLog,
} from '../../utils/helpers';
import {
  frameLoad,
  getFrameConfig,
  getPubRef,
  getRevJetConfig,
  buildRevJetBanner
} from '../../utils/revjet';

import styles from './SponsoredBanner.module.scss';
import { htmlAttributes, newRelicConstants } from '../../constants';
import useCookie from '../../hooks/useCookie';
import useFetch from '../../hooks/useFetch';

import {
  EXPERIMENT_COOKIE_MAX_TRIES,
  EXPERIMENT_COOKIE_NAME, EXPERIMENT_COOKIE_WAIT_INTERVAL,
  EXPERIMENT_FS_CLICK_NEXT_BTN_TIMEOUT,
  EXPERIMENT_FS_NAME_END_DATE,
  EXPERIMENT_FS_NAME_START_DATE, INNERVATE_GROUP_VALUE,
  TARGETED_CATEGORIES
} from '../../constants/experiments';
import {
  IS_KEVEL_TESTING_ENABLED,
  KEVEL_TEST_CATEGORY_GUIDS,
  KEVEL_TEST_SLUGS,
  adServerClients,
  bannerTypes,
  FORCE_KEVEL,
  SUPPRESSED_NVALUES
} from '../../constants/constants';

import { isCurrentDateInRange } from '../../utils/experiments';

let cx = classNames.bind(styles);
const hoistedImgFunction = ({ data }) => {
  const { isBrowse, avoidPreload, defaultBannerInfo } = data;
  if (isBrowse && !avoidPreload) {
    return [
      new LinkTag({
        rel: 'preload',
        as: 'image',
        href: defaultBannerInfo.imgSrc,
        id: 'spBannerPreloadLink',
      }),
    ];
  }
  return null;
};

const SponsoredBanner = (props) => {
  let {
    avoidPreload,
    browserId,
    pageContext,
    schema,
    slugId,
    utilizeRvData,
    showSponsoredBanner,
    contentfulDefaultBanner,
    enableForChapters
  } = props;
  const { mcvisID } = useThdCustomer();
  const [lastRequestScenario, setLastRequestScenario] = useState({});
  const [hasData, setHasData] = useState(false);
  const [tagProperties, setTagProperties] = useState({});
  const [sponsoredApiData, setBannerData] = useState({});
  const experienceContext = useContext(ExperienceContext);
  const sponsoredContentDefaultBanner = useConfigService('fs-prop:sponsored-default-banners');
  const callBannerApi = useConfigService('fs:callBannerApi') || false;

  // Ad Server Testing
  const kevelEnabled = useConfigService(IS_KEVEL_TESTING_ENABLED);
  const kevelTestCategoryGuids = useConfigService(KEVEL_TEST_CATEGORY_GUIDS);
  const kevelTestSlugs = useConfigService(KEVEL_TEST_SLUGS);
  const forceKevel = useConfigService(FORCE_KEVEL) || false;
  const suppressedNValues = useConfigService(SUPPRESSED_NVALUES);

  // Banner Experiment
  const configExperimentStartDate = useConfigService(EXPERIMENT_FS_NAME_START_DATE);
  const configExperimentEndDate = useConfigService(EXPERIMENT_FS_NAME_END_DATE);
  const clickNextBtnTimeout = useConfigService(EXPERIMENT_FS_CLICK_NEXT_BTN_TIMEOUT);
  const targetedCategories = useConfigService(TARGETED_CATEGORIES);
  const cookieName = useConfigService(EXPERIMENT_COOKIE_NAME);
  const innervateGroupValue = useConfigService(INNERVATE_GROUP_VALUE);
  const cookieWaitInterval = useConfigService(EXPERIMENT_COOKIE_WAIT_INTERVAL);
  const cookieMaxTries = useConfigService(EXPERIMENT_COOKIE_MAX_TRIES);

  const skip = shouldSkipDataModel(schema);
  const resp = useDataModel('container', {
    skip,
    variables: {
      context: {
        id: '4OH5iatZ0vFnTL58Qg8eJv'
      }
    }
  });
  const { data: defaultBanner } = resp;
  const validatedDefaultBanner = defaultBanner?.container || contentfulDefaultBanner;

  let externalDefaultBanner = {};
  const validBrowserId = browserId || experienceContext?.cookie?.adobeCookie?.MCMID;
  if (sponsoredContentDefaultBanner) {
    try {
      externalDefaultBanner = JSON.parse(sponsoredContentDefaultBanner);
      // eslint-disable-next-line no-empty
    } catch (error) {
    }
  }
  const defaultBannerInfo = getDefaultBanner(experienceContext, externalDefaultBanner, validatedDefaultBanner);
  const [isBranded, setIsBranded] = useState(isBrandFpn({ experienceContext, pageContext, suppressedNValues, bannerType: bannerTypes.STANDARD }));
  schema = getSchema({ schema, pageContext });
  const schemaId = schema + '__banner';

  // Banner Experiment
  const processedTargetedCategories = targetedCategories ? targetedCategories.split(',').map((category) => category.trim()) : [];
  const isOnExperimentCategoryPage = processedTargetedCategories.some((category) => experienceContext?.path?.includes(category));
  const isABTestActive = isCurrentDateInRange(configExperimentStartDate, configExperimentEndDate)
    && isOnExperimentCategoryPage && !experienceContext?.isConsumerApp;
  const abTestGroup = useCookie(cookieName, cookieWaitInterval, cookieMaxTries, isABTestActive);
  const isInnervateGroup = abTestGroup === innervateGroupValue;

  let adServer = adServerClients.PIQ;
  if (kevelEnabled || forceKevel) {
    adServer = checkIfPageIsInAdServerTest(forceKevel, pageContext, slugId, kevelTestCategoryGuids, kevelTestSlugs);

  }

  const data = {
    avoidPreload,
    channel: experienceContext.channel,
    isBrowse: pageContext?.isBrowse,
    defaultBannerInfo,
  };

  useHelmet('preloadBanner', { data }, hoistedImgFunction);

  const requestConfig = {
    adServer,
    mcvisID,
    browserId: validBrowserId,
    schema,
    schemaId,
    experienceContext,
    pageContext,
    setHasData,
    bannerType: bannerTypes.STANDARD,
    slugId,
    utilizeRvData,
    callBannerApi
  };

  // if callBannerApi = true, this hook will run fetch data to bannerapi
  const {
    data: bannerData, status, error, apiRequestTimestamp, apiResponseTimestamp
  } = useFetch(requestConfig);

  useEffect(() => {
    const adType = newRelicConstants.STANDARD_BANNER;
    if (status === 200) {
      requestConfig.startTime = apiRequestTimestamp;
      setHasData(true);
      buildRevJetBanner({ data: bannerData, requestConfig, adType });
    }
    if (status) {
      triggerNewRelic(adType, `BANNER-API-${status}`);
      // SEND TIMESTAMPS TO CALCULATE API RESPONSE TIME
      triggerNewRelicTimeLog(newRelicConstants.STANDARD_BANNER_API_RESPONSE, apiRequestTimestamp, apiResponseTimestamp);
      // SEND TIME FROM BANNER INITIATED TO BANNER WINNER RECEIVED
      triggerNewRelicTimeLog(newRelicConstants.STANDARD_BANNER_AD_INIT_TO_WINNER, apiRequestTimestamp, apiResponseTimestamp);
    }
  }, [status]);

  useEffect(() => {
    if (!callBannerApi) {
      const startTime = new Date().getTime();

      // For browse, search, category and event page schemas
      if ((isABTestActive && abTestGroup) || !isABTestActive) {
        if (pageContext?.label === 'browse-search') {
          const isValidScenario = shouldMakeApiRequest({
            lastRequestScenario,
            experienceContext,
            pageContext,
            schema,
            browserId: validBrowserId
          });

          if (isValidScenario || enableForChapters) {
            setLastRequestScenario({
              url: window.location.pathname + window.location.search,
              skus: getSkus(pageContext),
            });

            makeApiRequest({
              adServer,
              abTestGroup,
              isInnervateGroup,
              clickNextBtnTimeout,
              browserId: validBrowserId,
              schema,
              schemaId,
              experienceContext,
              pageContext,
              setHasData,
              setTagProperties,
              utilizeRvData,
              startTime,
              slugId,
              ignoreStoreId: true
            }).then((returnedData) => {
              if (returnedData.banner) {
                setBannerData(returnedData);
                let revJetConfig = getRevJetConfig({ experienceContext });
                setTagProperties({
                  _tag: revJetConfig.tag,
                  _key: revJetConfig.key,
                  piq_slot_id: returnedData.banner.slotId,
                  piq_campaign_id: returnedData.banner.campaignId,
                  piq_placement_id: returnedData.banner.placementId,
                  ct_url: returnedData.banner.onClickBeacon,
                  thd_itc_suffix: true,
                  _opts: {
                    autoscale: true,
                    b64: getPubRef(returnedData),
                    custom_domain: 'rma.homedepot.com',
                    embd_tag_id: schemaId
                  }
                });
              }
            });
          }
        } else if (showSponsoredBanner) {
          makeApiRequest({
            adServer,
            abTestGroup,
            isInnervateGroup,
            clickNextBtnTimeout,
            browserId: validBrowserId,
            schema,
            schemaId,
            experienceContext,
            pageContext,
            setHasData,
            setTagProperties,
            utilizeRvData,
            startTime,
            slugId,
            ignoreStoreId: true
          }).then((returnedData) => {
            if (returnedData.banner) {
              setBannerData(returnedData);
              let revJetConfig = getRevJetConfig({ experienceContext });
              setTagProperties({
                _tag: revJetConfig.tag,
                _key: revJetConfig.key,
                piq_slot_id: returnedData.banner.slotId,
                piq_campaign_id: returnedData.banner.campaignId,
                piq_placement_id: returnedData.banner.placementId,
                ct_url: returnedData.banner.onClickBeacon,
                thd_itc_suffix: true,
                _opts: {
                  autoscale: true,
                  b64: getPubRef(returnedData),
                  custom_domain: 'rma.homedepot.com',
                  embd_tag_id: schemaId
                }
              });
            }
          });
        }
      }
    }
  }, [pageContext?.data, slugId, schema, validBrowserId, abTestGroup, isABTestActive]);

  useEffect(() => {
    initImpressionWatchers(['NUCLEUS_BANNER']);
    const slideEl = document.querySelector('.carousel .slide');
    if (slideEl) {
      slideEl.style.background = '#f5f5f5';
    }
  }, [hasData]);

  const onBannerLoad = useCallback(() => {
    const frameConfig = getFrameConfig({ data: sponsoredApiData, requestConfig });
    if (frameConfig) {
      frameLoad({ frameConfig, data: sponsoredApiData, requestConfig });
    }
  }, [tagProperties]);

  if (isBranded) {
    return null;
  }

  return (
    <div
      className={cx('sponsored_banner_container')}
      id="sponsored-standard-banner-nucleus"
      data-component="SponsoredBanner"
    >

      {!hasData && (
        <div
          id="default_banner_carousel"
          style={defaultBannerInfo.spacingCss}
          data-testid="default-standard-banner"
        >
          <a href={defaultBannerInfo.href}>
            {/* eslint-disable-next-line */}
            <img
              className={cx('sponsored_banner_image')}
              alt="sponsored banner"
              src={defaultBannerInfo.imgSrc}
            />
          </a>
        </div>
      )}

      {hasData && (

        <Carousel multiItem={false} totalItems={2}>
          <div
            id="default_banner_carousel"
            style={defaultBannerInfo.spacingCss}
            data-testid="default-standard-banner"
          >
            <a href={defaultBannerInfo.href}>
              {/* eslint-disable-next-line */}
              <img
                className={cx('sponsored_banner_image')}
                alt="sponsored banner"
                src={defaultBannerInfo.imgSrc}
              />
            </a>
          </div>

          {callBannerApi
            ? (
              <div
                id={schemaId}
                className="nucleus-banner"
                data-usenewimpressionlogic="true"
                data-testid="sponsored-standard-banner"
              />
            )
            : (
              <div
                id={schemaId}
                data-testid="sponsored-standard-banner"
              >
                <RevJetTag
                  tagProperties={tagProperties}
                  onLoad={onBannerLoad}
                />
              </div>
            )}

        </Carousel>
      )}
    </div>
  );
};

SponsoredBanner.dataModel = {
  container: params({
    context: customType('QueryContext')
      .shape({
        id: stringType()
      })
  })
    .shape({
      SponsoredTopBanner: fragment()
        .shape({
          imgDesktop: stringType(),
          imgMobile: stringType(),
          clickthruUrl: stringType(),
          specialDefaultBanner: shapeType({
            imgDesktop: stringType(),
            imgMobile: stringType(),
            clickthruUrl: stringType(),
            useSpecialBanner: boolType()
          })
        }),
    }),
};

SponsoredBanner.propTypes = {
  schema: string,
  browserId: string,
  slugId: string,
  pageContext: shape({
    label: string,
    data: shape(),
    keyword: string,
    isSearch: bool,
    isCategory: bool,
    isBrowse: bool,
  }),
  avoidPreload: bool,
  utilizeRvData: bool,
  showSponsoredBanner: bool,
  enableForChapters: bool,
  contentfulDefaultBanner: object,
};

SponsoredBanner.defaultProps = {
  avoidPreload: true,
  browserId: '123456',
  pageContext: {},
  schema: '',
  slugId: '',
  utilizeRvData: false,
  showSponsoredBanner: true,
  enableForChapters: false,
  contentfulDefaultBanner: {},
};

export { SponsoredBanner };
