/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { extend } from '@thd-nucleus/data-sources';
import { Button } from '@thd-olt-component-react/button';
import { Col } from '@thd-olt-component-react/grid';
import { getAppliedRefinements, getRefinementsByDimension } from '../../product-results-helpers';
import { StandardDimension } from '../Dimensions/StandardDimension';
import { LinkDimension } from '../Dimensions/LinkDimension';
import { RatingRadioDimension } from '../Dimensions/RatingRadioDimension';
import { VisualDimension } from '../Dimensions/VisualDimension';
import { AppliedRefinement } from '../Refinements/AppliedRefinement';
import { NumericRangeDimension } from '../Dimensions/NumericRangeDimension';

import '../../styles/drawer.scss';
import { ColorSwatchDimension } from '../Dimensions/ColorSwatchDimension';
import { ResultsSortBy } from '../ResultsSortBy';

export const DrawerAndAppliedRefinements = ({
  appliedDimensions,
  breadCrumbs,
  clearAll,
  enableMultiStore,
  isOpen,
  loading,
  metadata,
  multiStoreSelection,
  normalizedDimensions,
  onDimensionsChange,
  onDisplayData,
  onMultiStoreSelectionChange,
  onRefinementChange,
  onRefinementSelected,
  onSortByChange,
  toggleDrawer,
  onSortBy,
}) => {
  const CLOSE_BUTTON = 'https://assets.thdstatic.com/images/v1/close-symbol-orange.svg';

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => {
      document.body.classList.remove('filter-and-sort--no-scroll');
    };
  }, []);
  const { channel } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile';
  const headerTitle = isMobile ? 'Sort & Filter' : 'Filters';

  if (isMounted && isOpen) {
    document.body.classList.add('filter-and-sort--no-scroll');
  }

  const handleClick = (event) => {
    event.preventDefault();
    clearAll();
  };
  const closeDrawer = () => {
    document.body.classList.remove('filter-and-sort--no-scroll');
    toggleDrawer(true);
  };

  const hasActiveFilters = (label) => {
    if (appliedDimensions?.length < 0) {
      return false;
    }
    return appliedDimensions.find((dim) => dim.label === label && dim.refinements.length > 0) !== undefined;
  };

  const clickOnFitCompatibility = () => {
    if (isOpen) {
      closeDrawer();
    }
  };

  const generateDrawerDimensions = () => {
    return (
      <>
        {normalizedDimensions.map((dimension) => {
          const {
            label, dimensionId, isVisualDimension, isNumericFilter, isColorSwatch,
          } = dimension;

          const hasFilter = ['BRAND', 'COLOR FAMILY', 'PATTERN TYPE', 'THEME', 'FEATURED KEYWORDS',
            'CUSTOMER SEARCHED KEYWORDS', 'POPULAR KEYWORDS'].indexOf(label.toUpperCase()) > -1;
          const hasFitCompatibility = [
            'DEPTH (EXCLUDING HANDLES) (IN.)',
            'HEIGHT TO TOP OF DOOR HINGE (IN.)',
            'HEIGHT TO TOP OF REFRIGERATOR (IN.)',
            'INSTALLATION DEPTH',
            'REFRIGERATOR FIT WIDTH',
            'TOTAL CAPACITY (CU. FT.)',
            'CAPACITY (CU. FT.) - REFRIGERATORS',
            'REFRIGERATOR CAPACITY (CU. FT.)',
            'DEPTH (INCLUDING HANDLES) (IN.)'
          ].includes(label.toUpperCase());
          if (isVisualDimension) {
            return (
              <VisualDimension
                drawer
                key={dimensionId}
                collapsed
                onChange={onRefinementSelected}
                dimension={dimension}
                multiFacet
              />
            );
          }

          if (isNumericFilter) {
            return (
              <NumericRangeDimension
                collapsed
                dimension={dimension}
                drawer
                enableMultiStore={enableMultiStore}
                isActive={hasActiveFilters(label)}
                key={dimensionId}
                metadata={metadata}
                multiFacet
                multiStoreSelection={multiStoreSelection}
                onChange={onRefinementSelected}
                onDisplayData={onDisplayData}
                onMultiStoreSelectionChange={onMultiStoreSelectionChange}
                selectedRefinements={getRefinementsByDimension(appliedDimensions, dimension)}
              />
            );
          }

          if (isColorSwatch) {
            return (
              <ColorSwatchDimension
                collapsed
                dimension={dimension}
                drawer
                isActive={hasActiveFilters(label)}
                key={dimensionId}
                multiFacet
                onChange={onRefinementSelected}
                selectedRefinements={getRefinementsByDimension(appliedDimensions, dimension)}
              />
            );
          }

          switch (label) {
          case 'Category':
            return (
              <LinkDimension
                breadCrumbs={breadCrumbs}
                drawer
                collapsed
                dimension={dimension}
                key={dimensionId}
                onChange={onRefinementChange}
                multiFacet
              />
            );
          case 'Review Rating':
            return (
              <RatingRadioDimension
                collapsed
                dimension={dimension}
                key={dimensionId}
                onChange={onRefinementSelected}
                multiFacet
                isActive={hasActiveFilters(label)}
              />
            );
          default:
            return (
              <StandardDimension
                filter={hasFilter}
                fitCompatibility={hasFitCompatibility}
                onFitCompatibilityClick={clickOnFitCompatibility}
                priceRange={label.toUpperCase() === 'PRICE'}
                toggleSwitch={label.toUpperCase() === 'AVAILABILITY'}
                collapsed
                drawer
                dimension={dimension}
                key={dimensionId}
                onChange={onRefinementSelected}
                onDisplayData={onDisplayData}
                enableMultiStore={enableMultiStore}
                onMultiStoreSelectionChange={onMultiStoreSelectionChange}
                multiStoreSelection={multiStoreSelection}
                multiFacet
                isMobile={isMobile}
                isActive={hasActiveFilters(label)}
                selectedRefinements={getRefinementsByDimension(appliedDimensions, dimension)}
              />
            );
          }
        })}
      </>
    );
  };

  const generateClearButton = () => {
    if (appliedDimensions.length > 0) {
      return <a className="filter__clear-all--blue" href="#" onClick={handleClick}>Clear All</a>;
    }
    return <span className="filter__clear-all--grey">Clear All</span>;
  };

  const appliedRefinements = getAppliedRefinements(appliedDimensions);

  const drawerClasses = classNames('drawer', {
    // eslint-disable-next-line
    'grid': isOpen,
    'drawer--display-none': !isOpen
  });

  const fadeClasses = classNames('drawer__shadow', {
    'drawer__shadow--fade': loading
  });

  const headerClasses = classNames('drawer__header', 'drawer__header--padding');
  const sortByClasses = classNames('drawer__sort-by', 'drawer__sort-by--padding');
  const filterTitleClasses = classNames('filter__title', 'filter__title--small');
  const drawerFiltersClasses = classNames('drawer__filters', 'drawer__filters--padding');
  const appliedResultsClasses = classNames('results-applied results-applied__drawer', 'results-applied--padding');
  const drawerContentClasses = classNames('drawer__content', 'drawer__content--narrow');

  return (
    <div className={drawerClasses}>
      <div className={drawerContentClasses}>
        <div className={headerClasses}>
          <div className="drawer__title">{headerTitle}</div>
          <button className="drawer__close" type="button" onClick={closeDrawer}>
            <img alt="close-button" className="drawer__close--size" src={CLOSE_BUTTON} height="20" width="20" />
          </button>
        </div>
        <Col>
          {isMobile && (
            <div className={sortByClasses}>
              <ResultsSortBy
                drawer
                onSortChange={onSortByChange}
                onSortBy={onSortBy}
              />
            </div>
          )}
          <div className={drawerFiltersClasses}>
            <div className={filterTitleClasses}>Filters</div>
            {generateClearButton()}
          </div>
          <div className={appliedResultsClasses}>
            {appliedRefinements.map((refinement, index) => {
              const {
                dimensionName,
                refinementLabel,
                refinementKey,
                url
              } = refinement;
              return (
                <AppliedRefinement
                  key={`drawer-${index}`}
                  index={index}
                  deselectUrl={url}
                  dimensionName={dimensionName}
                  label={refinementLabel}
                  refinementKey={refinementKey}
                  onChange={onRefinementSelected}

                />
              );
            })}
          </div>
          <div className="dimensions">
            {generateDrawerDimensions()}
          </div>
        </Col>
        <div className="grid drawer__sticky-nav drawer__sticky-nav--narrow">
          <div className="drawer__sticky-nav--results-button button-centered">
            <Button
              onClick={onDimensionsChange}
              className="drawer__fixed-width-button"
            >
              <span>View Results</span>
            </Button>
          </div>
        </div>
      </div>
      <div className={fadeClasses} onClick={closeDrawer} />
    </div>
  );
};

DrawerAndAppliedRefinements.propTypes = {
  appliedDimensions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      refinements: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired
        })
      )
    })
  ).isRequired,
  breadCrumbs: PropTypes.arrayOf(
    PropTypes.shape({})
  ),
  isOpen: PropTypes.bool,
  loading: PropTypes.bool,
  metadata: PropTypes.shape({}),
  normalizedDimensions: PropTypes.arrayOf(
    PropTypes.shape({})
  ),
  onRefinementChange: PropTypes.func.isRequired,
  onRefinementSelected: PropTypes.func.isRequired,
  onSortByChange: PropTypes.func.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  onDisplayData: PropTypes.shape({
    onDisplay: PropTypes.string,
    onDisplayChange: PropTypes.func,
    isStoreDisplay: PropTypes.string,
    itemCount: PropTypes.string
  }),
  enableMultiStore: PropTypes.bool,
  multiStoreSelection: PropTypes.string,
  onMultiStoreSelectionChange: PropTypes.func,
  onDimensionsChange: PropTypes.func.isRequired,
  clearAll: PropTypes.func.isRequired,
  onSortBy: PropTypes.func,
};

DrawerAndAppliedRefinements.defaultProps = {
  breadCrumbs: [],
  isOpen: false,
  loading: false,
  metadata: null,
  normalizedDimensions: [],
  onDisplayData: {
    onDisplay: 'false',
    onDisplayChange: null,
    isStoreDisplay: 'false',
    itemCount: null
  },
  enableMultiStore: false,
  multiStoreSelection: null,
  onMultiStoreSelectionChange: null,
  onSortBy: null,
};

DrawerAndAppliedRefinements.displayName = 'DrawerAndAppliedRefinements';

DrawerAndAppliedRefinements.dataModel = extend(StandardDimension);
