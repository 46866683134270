import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { extend } from '@thd-nucleus/data-sources';
import {
  Tile, TileContent, Typography, TileMedia, TileText
} from '@one-thd/sui-atomic-components';
import {
  ConfirmedFilled, Delivery, DeliveryExpress, InStorePickup, Store
} from '@one-thd/sui-icons';
import { StoreSelector } from '../StoreSelector/StoreSelector';
import '../../styles/availability-filters.scss';
import { ProductResultsContext } from '../ProductResultsContext';

const AvailabilityRefinement = ({
  dimension,
  onChange,
  refinement,
  onDisplay,
  multiFacet,
  active,
  isMobile,
}) => {

  const { selected } = refinement;

  const isSelected = (!multiFacet && !!selected) || (multiFacet && active);
  const { data } = useContext(ProductResultsContext);
  const { isStoreDisplay } = data?.searchModel?.metadata || false;
  const isUnavailable = parseInt(refinement.recordCount, 10) === 0;
  const fillOrange = '#F96302';

  const handleOnChange = (event) => {
    if (onChange) {
      onChange({
        refinement, dimension, onDisplay
      });
    }
  };

  const generateRefinementLabel = (isRefinementDisabled) => {
    // eslint-disable-next-line no-nested-ternary
    const displayLabel = refinement?.refinementKey === '1z175a5'
      ? isStoreDisplay === 'false' ? 'In Stock at Store Today' : 'On Display In Store'
      : refinement?.label;

    return (
      <Typography
        variant="body-base"
        color={isRefinementDisabled ? 'inactive' : 'primary'}
        align={isMobile ? 'center' : 'left'}
      >
        {displayLabel}
      </Typography>
    );
  };

  const generateIcon = (isUnavailableArg) => {

    if (isUnavailableArg) {
      return <InStorePickup />;
    }
    const { refinementKey } = refinement || null;
    if (refinementKey === '1z175a5' && isStoreDisplay === 'false') {
      return <InStorePickup style={{ fill: fillOrange }} />;
    }
    if (refinementKey === '1z175a5' && isStoreDisplay === 'true') {
      return <Store style={{ fill: fillOrange }} />;
    }
    if (refinementKey === 'bwo6i') {
      return <Delivery style={{ fill: fillOrange }} />;
    }
    if (refinementKey === '1z175cq') {
      return <DeliveryExpress style={{ fill: fillOrange }} />;
    }
    return null;
  };

  const refinementClassName = classNames('refinement__availability', {
    'refinement__availability--desktop': !isMobile,
    'refinement__availability--mini': isMobile,
    'refinement__availability--selected': isSelected,
    'refinement__availability--disabled': isUnavailable,
    'refinement__availability--default-border': isUnavailable || !isSelected,
  });

  return (
    <>
      <Tile
        className={refinementClassName}
        value={refinement?.label}
        aria-label={refinement?.label}
        onChange={handleOnChange}
        orientation="vertical"
        unavailable={isUnavailable}
        disabled={isUnavailable}
      >
        <TileContent
          className="refinement__availability--tile"
          orientation="horizontal"
          alignItems={isMobile ? 'center' : 'start'}
          disableGutters
        >
          <div className="refinement__availability--icon">
            {generateIcon(isUnavailable)}
          </div>
          <div className="refinement__availability--label">
            <TileText>
              {generateRefinementLabel()}
            </TileText>
          </div>
          {isSelected && (
            <div className="refinement__availability--selected-icon">
              <ConfirmedFilled style={{ fill: fillOrange }} size="small" />
            </div>
          )}
        </TileContent>
      </Tile>
    </>
  );
};

AvailabilityRefinement.propTypes = {
  dimension: PropTypes.shape({}),
  refinement: PropTypes.shape({
    label: PropTypes.string,
    recordCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    refinementKey: PropTypes.string,
    selected: PropTypes.bool,
    url: PropTypes.string,
  }),
  onDisplay: PropTypes.string,
  onChange: PropTypes.func,
  multiFacet: PropTypes.bool,
  active: PropTypes.bool,
  isMobile: PropTypes.bool,
};

AvailabilityRefinement.defaultProps = {
  dimension: null,
  refinement: null,
  onDisplay: 'false',
  onChange: null,
  multiFacet: false,
  active: false,
  isMobile: false,
};

AvailabilityRefinement.displayName = 'AvailabilityRefinement';

AvailabilityRefinement.dataModel = extend(StoreSelector);

export { AvailabilityRefinement };
