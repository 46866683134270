import React from 'react';
import classNames from 'classnames/bind';
import {
  string, arrayOf, shape, bool, func, oneOfType, number
} from 'prop-types';
import { ConfirmedFilled } from '@one-thd/sui-icons';
import styles from '../../styles/primary-filters.scss';

const MultiStateDrawerRefinement = ({
  refinement, dimension, onClick, selected
}) => {
  const cx = classNames.bind(styles);
  const handleOnClick = () => {
    onClick({ refinement, dimension });
  };

  /**
   * unselected
   * selected
   */
  const refinementClassNames = cx('multi-select-refinement', {
    'multi-select-refinement--selected': selected,
    'multi-select-refinement-customprice': dimension.label === 'Price',
    'multi-select-refinement-drawer': dimension.label !== 'Price'
  });

  const tickIconClassNames = cx('multi-select-refinement--selected--tick');

  return (
    <button
      type="button"
      className={refinementClassNames}
      onClick={handleOnClick}
    >
      {refinement.label}
      { dimension.label === 'Price' && selected && (
        <span className={tickIconClassNames}><ConfirmedFilled size="small" /></span>
      )}
    </button>
  );
};

MultiStateDrawerRefinement.displayName = 'DrawerRefinement';
MultiStateDrawerRefinement.propTypes = {
  dimension: shape({
    dimensionId: string,
    label: string,
    refinements: arrayOf(
      shape({})
    ),
  }).isRequired,
  refinement: shape({
    label: string,
    recordCount: oneOfType([string, number]),
    refinementKey: string,
    selected: bool,
    url: string
  }).isRequired,
  onClick: func.isRequired,
  selected: bool,
};

MultiStateDrawerRefinement.defaultProps = {
  selected: false,
};

export { MultiStateDrawerRefinement };